import { ConfigProvider, Input, Spin } from "antd";
import moment from "moment";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LabUtil } from "../../Utils/lab";
import qr from "../../assets/images/qr.png";
import search from "../../assets/images/search.png";
import CustomEmptyData from "../../common/CustomEmptyData";
import { GirlWithLapTopIcon } from "../../common/Icons";
import { CardResultModel } from "../../models/origin";
import SupplierCardService from "../../services/supplier";
import FooterOrigin from "../FooterOrigin/FooterOrigin.presenter";
import Header from "../HeaderOrigin/HeaderOrigin.presenter";
import styles from "./HomePageOrigin.module.scss";

const HomepagePresenter = () => {
  const { t } = useTranslation([
    "translation",
    "supplier",
    "message",
    "dentalLab",
  ]);

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [data, setData] = useState<any>([]);
  const [dataInfo, setDataInfo] = useState<any>([]);
  const [error, setError] = useState(false);

  const onCheck = async () => {
    setLoading(true);
    try {
      const res = await SupplierCardService.checkOriginCard(searchValue);

      if (res && (res.status === 200 || res.status === 201)) {
        const data: CardResultModel = res.data.data;

        const valueOfTeeth = LabUtil.getNumberOfTeeth(
          data.request?.teeth || [],
          t
        );
        console.log(t`("dentalLab:${valueOfTeeth.join("")}")`);

        if (data) {
          const dataSource = [
            { title: "Số Thẻ:", value: data.code },
            { title: "Họ Và Tên:", value: data.patientName },
            { title: "Nha Khoa:", value: data.dentalName },
            {
              title: "Labo:",
              value: data.request?.customer.businessName,
            },
            {
              title: "Loại Phục Hình:",
              value: data.request?.orderDetail?.productName,
            },
            {
              title: "Ngày Cấp:",
              value: moment(data.createdAt).format("DD/MM/YYYY"),
            },
            { title: "Vị Trí:", value: valueOfTeeth.join(", ") },
            { title: "Số Lượng:	", value: data.request.quantity },
          ];
          setData(dataSource);
          const dataSourceInfo = [
            { title: "Số thẻ", value: data.code },
            { title: "Họ và tên", value: data.patientName },
            { title: "Nha khoa", value: data.dentalName },
            {
              title: "Ngày cấp:",
              value: moment(data.createdAt).format("DD/MM/YYYY"),
            },
            {
              title: "Labo",
              value: data.request?.customer.businessName,
            },
          ];
          setDataInfo(dataSourceInfo);
        }
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e?.response?.data?.errors[0] === "CODE_CARD_DOSE_NOT_EXITS") {
        setError(true);
      } else {
        setError(false);
      }
    }
  };

  return (
    <ConfigProvider theme={{ hashed: false }}>
      <div className="main">
        <Header />

        <div className={styles.homeOrigin}>
          <div className="container">
            <div className={styles.homeOrigin__content}>
              <h2>TRUY XUẤT NGUỒN GỐC VẬT LIỆU</h2>
              <p>
                Quý khách nhập mã thẻ vào ô dưới đây để tra cứu thông tin nguồn
                gốc vật liệu
              </p>
            </div>
            <div className={styles.homeOrigin__search}>
              <div>
                <Input
                  placeholder="Mã thẻ"
                  onPressEnter={(e: any) => {
                    onCheck();
                    if (data.length) setData([]);
                  }}
                  onChange={(e: any) => {
                    e.target.value;
                    setSearchValue(e.target.value);
                    if (
                      e?.response?.data?.errors[0] ===
                      "CODE_CARD_DOSE_NOT_EXITS"
                    ) {
                      setError(true);
                    } else {
                      setError(false);
                    }
                  }}
                  className={styles.homeOrigin__search__item}
                  suffix={
                    <div
                      onClick={() => {
                        onCheck();
                        if (data.length) setData([]);
                      }}
                    >
                      <Image
                        src={search}
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
            {loading && <Spin />}
            {error && (
              <>
                <CustomEmptyData
                  icon={<GirlWithLapTopIcon />}
                  title={
                    "Không tìm thấy thẻ bảo hành của Bạn, vui lòng nhập lại!"
                  }
                  messages={[
                    "Không tìm thấy thẻ bảo hành của Bạn, vui lòng nhập lại!",
                  ]}
                  breakLine
                />
              </>
            )}
            <>
              {!!data.length && !error && (
                <div className={styles.homeOrigin__card}>
                  <div className={styles.homeOrigin__card__main}>
                    <table className={styles.homeOrigin__card__main__table}>
                      {dataInfo.map((item: any, idx: any) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td
                                className={
                                  styles.homeOrigin__card__main__table__item
                                }
                              >
                                {item?.value}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                    <div className={styles.homeOrigin__card__main__qr}>
                      <Image src={qr} alt="" />
                    </div>
                  </div>

                  <div className={styles.homeOrigin__card__info}>
                    <p>THÔNG TIN CHI TIẾT</p>
                    <table className={styles.homeOrigin__card__info__table}>
                      {data.map((item: any, idx: any) => {
                        return (
                          <tr key={idx}>
                            <td
                              className={
                                styles.homeOrigin__card__info__table__left
                              }
                            >
                              {item.title}
                            </td>
                            <td
                              className={
                                styles.homeOrigin__card__info__table__right
                              }
                            >
                              {item.value}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              )}
            </>
            <>
              {" "}
              {!data.length && !error && (
                <>
                  <div className={styles.homeOrigin__description}>
                    <div className={styles.homeOrigin__description__list}>
                      <div
                        className={styles.homeOrigin__description__list__item}
                      >
                        <p>
                          • Bác sĩ điều trị và Labo nha khoa chịu trách nhiệm
                          bảo hành, đảm bảo tính thẩm mỹ và chức năng của phục
                          hình.
                        </p>
                        <p>
                          • Khuyến nghị bảo hành 15 năm cho các phục hình răng
                          sứ làm từ vật liệu Lava được cung cấp bởi 3D.
                        </p>
                        <p>
                          • Chế độ bảo hành chỉ được áp dụng cho các phục hình
                          tuân theo đúng chỉ định và khuyến cáo của Bác sĩ nha
                          khoa.
                        </p>
                        <p style={{ fontWeight: "500" }}>
                          • Truy xuất nguồn gốc:
                          <span>
                            Nhập ID Code trên website{" "}
                            <Link href="https://3dlava.com.vn">
                              https://3dlava.com.vn
                            </Link>
                          </span>
                          <span> Hoặc scan QR Code trên thẻ.</span>
                        </p>
                        <p>
                          • Nếu có bất kỳ vấn đề gì liên quan đến thẻ{" "}
                          <strong>truy xuất nguồn gốc</strong> vui lòng liên hệ:{" "}
                          <a>0789 423 330</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
        <FooterOrigin />
      </div>
    </ConfigProvider>
  );
};

export default HomepagePresenter;
