import Image from "next/image";
import fb from "../../assets/images/fb.png";
import styles from "./FooterOrigin.module.scss";
import Link from "next/link";

const FooterOriginPresenter = () => {
  return (
    <div className={styles.footerOrigin}>
      <div className={styles.footerOrigin__main}>
        <div className="container">
          <div className={styles.footerOrigin__main__container__list}>
            <ul>
              <Link href="https://3dlava.com.vn/">Trang chủ</Link>
              <Link href="https://3dlava.com.vn/gioi-thieu/">Giới thiệu</Link>
              <Link href="https://3dlava.com.vn/lien-he/">Liên hệ</Link>
            </ul>
            <div className={styles.footerOrigin__main__container__list__item}>
              <p>3M ESPE Lava</p>
              <Image src={fb} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerOrigin__sub}>
        <div className="container">
          <div className={styles.footerOrigin__sub__container__list}>
            <ul>
              <li>Copyright © 2023 Lava™, All Rights Reserved.</li>

              <li>
                <Link href="https://3dlava.com.vn/thong-tin-phap-ly/">
                  Thông tin pháp lý
                </Link>
                |
                <Link href="https://3dlava.com.vn/thong-tin-phap-ly/">
                  Chính sách bảo mật
                </Link>
              </li>
            </ul>
            <div className={styles.footerOrigin__sub__container__list__item}>
              <Link href="https://3dlava.com.vn/lien-he/">Hổ trợ 3MLava</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterOriginPresenter;
