import Head from "next/head";
import HomepagePresenter from "../components/HomePageOrigin/HomePageOrigin.presenter";

export default function Home() {
  return (
    <>
      <Head>
        <title>Tra cứu bảo hành - Đồng Đến Đích - Wisere Platform</title>
        <meta
          name="description"
          content="Tra cứu bảo hành - Đồng Đến Đích - Wisere Platform"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div>
        <HomepagePresenter />
      </div>
    </>
  );
}
