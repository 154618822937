import Hamburger from "hamburger-react";
import Image from "next/image";
import { useState } from "react";
import cart from "../../assets/images/cart.png";
import doctor from "../../assets/images/doctor.png";
import labo from "../../assets/images/labo.png";
import logo from "../../assets/images/logo.png";
import patient from "../../assets/images/patient.png";
import styles from "./HeaderOrigin.module.scss";
import Link from "next/link";

const Header = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.headerOrigin}>
      <div className="container">
        <div className={styles.headerOrigin__list}>
          <div className={styles.headerOrigin__list__item}></div>
          <div className={styles.headerOrigin__list__item}>
            <div className={styles.headerOrigin__list__item__sub}>
              <Image src={doctor} alt="" />
              <Link href="https://3dlava.com.vn/bac-si/">Bác Sĩ</Link>
            </div>
            <div className={styles.headerOrigin__list__item__sub}>
              <Image src={patient} alt="" />
              <Link href="https://3dlava.com.vn/benh-nhan/">Bệnh nhân</Link>
            </div>
            <div className={styles.headerOrigin__list__item__sub}>
              <Image src={labo} alt="" />
              <Link href="https://3dlava.com.vn/lab-labo/">Labo Nha Khoa </Link>
            </div>
          </div>
        </div>
        {!!visible && <div className={styles.headerOrigin__marsk}></div>}
        <div className={styles.headerOrigin__bar}>
          <div className={styles.headerOrigin__bar__menuBar}>
            <Hamburger onToggle={() => setVisible(!visible)} size={25} />
          </div>
          <div className={styles.headerOrigin__bar__logo}>
            <Link href="https://3dlava.com.vn/">
              <Image src={logo} alt="" />
            </Link>
          </div>
          <div className={styles.headerOrigin__bar__cart}>
            <Image src={cart} alt="" />
          </div>
        </div>
      </div>

      <div
        className={
          !visible ? styles.headerOrigin__sub__active : styles.headerOrigin__sub
        }
      >
        <ul>
          <Link href="https://3dlava.com.vn/">Trang chủ</Link>
          <Link href="https://3dlava.com.vn/gioi-thieu/">Giới thiệu</Link>
          <Link href="https://3dlava.com.vn/truy-xuat-nguon-goc/">
            Truy xuất nguồn gốc
          </Link>
          <Link href="https://3dlava.com.vn/quy-trinh-gan-phuc-hinh-vinh-vien/">
            Hướng dẫn mài sửa soạn
          </Link>
          <Link href="https://3dlava.com.vn/quy-trinh-gan-phuc-hinh-vinh-vien/">
            Các bước gắn phục hình
          </Link>
          <Link href="https://3dlava.com.vn/case-lam-sang/">Case lâm sàng</Link>
          <Link href="https://3dlava.com.vn/quang-cao/">Quảng cáo</Link>
          <Link href="https://3dlava.com.vn/lab-labo/">Lab/Labo</Link>
        </ul>
      </div>
    </div>
  );
};

export default Header;
